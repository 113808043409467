@import url(https://fonts.googleapis.com/css?family=Lato:400,700&subset=latin-ext);
.App #top-bar {
  position: fixed;
  height: 60px;
  width: 100%;
  top: 0;
  background-color: #f4f5f6;
  z-index: 10; }
  .App #top-bar .navbar-brand {
    height: 100%;
    color: #0F74BC;
    -webkit-transition: all 300ms;
    transition: all 300ms; }
    .App #top-bar .navbar-brand:hover {
      color: #094875; }
    .App #top-bar .navbar-brand img {
      height: 100%; }

.App #sidebar-container {
  position: fixed;
  top: 60px;
  left: 0;
  width: 0px;
  padding-bottom: 60px;
  height: 100%; }
  .App #sidebar-container #sidebar {
    width: 100%;
    height: 100%;
    background-color: #242627; }
    .App #sidebar-container #sidebar .nav-item {
      -webkit-transition: all 300ms;
      transition: all 300ms; }
      .App #sidebar-container #sidebar .nav-item:hover {
        background-color: #5B5F62; }
      .App #sidebar-container #sidebar .nav-item .nav-link {
        color: #FFFFFF; }

.App #main {
  margin-top: 60px;
  margin-left: 0px;
  padding: 1rem 0; }

.App #action-bar {
  height: 60px;
  padding: 0 1rem 0 0;
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: row; }
  .App #action-bar button {
    margin-left: 0.5rem; }
    .App #action-bar button svg {
      margin-right: 0.5rem; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ContentWithSidebar .ContentToolbar {
  position: fixed;
  top: 0;
  right: 0;
  padding: 60px 315px 10px 15px;
  z-index: 1;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  width: 100%;
  height: 95px; }
  .ContentWithSidebar .ContentToolbar .bp3-navbar-group {
    height: 25px; }
  .ContentWithSidebar .ContentToolbar.bp3-dark {
    background-color: #5c7080; }
  .ContentWithSidebar .ContentToolbar + .Content {
    margin-top: 45px; }

.ContentWithSidebar .Content {
  padding-top: 20px;
  margin-right: 300px;
  display: flex;
  justify-content: center; }
  .ContentWithSidebar .Content > div {
    width: 100%;
    max-width: 600px; }

.ContentWithSidebar .Sidebar {
  position: fixed;
  right: 0;
  top: 0;
  padding: 65px 15px 15px;
  z-index: 2;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  background-color: #5c7080;
  height: 100%;
  width: 300px;
  overflow-y: auto; }
  .ContentWithSidebar .Sidebar > * {
    margin-bottom: 10px; }

#root .bp3-button-group.full-width {
  width: 100%; }
  #root .bp3-button-group.full-width .bp3-button {
    flex: 1 1 auto; }

.SessionsList {
  margin-top: 20px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.SessionsListTable tr.selected {
  background-color: #bfccd6; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.AddExerciseModal .bp3-heading {
  margin-bottom: 2rem; }

.AddExerciseModal .exercise-select .bp3-popover-target {
  margin-bottom: 1rem; }

.AddExerciseModal .exercise-select .bp3-popover-target, .AddExerciseModal .exercise-select .bp3-button {
  width: 100%; }

.bp3-popover-content .select-exercise-choice {
  cursor: pointer;
  background-color: #e1e8ed;
  padding: 8px;
  margin-bottom: 2px; }
  .bp3-popover-content .select-exercise-choice h4, .bp3-popover-content .select-exercise-choice p {
    margin: 0; }
  .bp3-popover-content .select-exercise-choice:hover {
    background-color: #ced9e0; }



.Exercise {
  position: relative; }
  .Exercise .card-header {
    display: flex;
    margin-bottom: 0;
    align-items: center; }
    .Exercise .card-header .card-header-drag-icon {
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 10px; }
    .Exercise .card-header .card-header-content {
      width: 100%; }
    .Exercise .card-header .card-header-button {
      flex-shrink: 0;
      flex-grow: 0;
      height: 30px;
      margin-left: 10px; }
  .Exercise #exercise-name {
    margin: 0.5rem 0 1.5rem; }
  .Exercise .exercise-title {
    height: calc(4rem + 2px);
    font-size: 2.5rem;
    margin-bottom: 1rem; }
  .Exercise .card {
    margin-bottom: 0.5rem; }
    .Exercise .card .card-header {
      padding-left: 0.75rem;
      padding-right: 0.75rem; }
      .Exercise .card .card-header input {
        padding: 1.5rem 0.5rem; }
  .Exercise .question .question-header {
    background-color: #0F74BC; }
    .Exercise .question .question-header .drag-bar {
      text-align: center; }
    .Exercise .question .question-header textarea {
      font-size: 1.5rem;
      color: #fff; }
      .Exercise .question .question-header textarea:focus {
        color: #495057; }
  .Exercise .question .answer {
    color: #495057; }
    .Exercise .question .answer label {
      text-align: right; }
    .Exercise .question .answer .form-group:last-child {
      margin-bottom: 0; }
    .Exercise .question .answer .toggleable {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      color: red;
      -webkit-transition: color 300ms linear;
      transition: color 300ms linear; }
      .Exercise .question .answer .toggleable.correct {
        color: green; }
    .Exercise .question .answer .card-body:empty {
      padding: 0; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.fade-leave {
  opacity: 1; }

.fade-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.zenek {
  opacity: 0.01; }

.NumericInput {
  width: 100%; }
  .NumericInput .bp3-input-group {
    width: 100%;
    margin-left: -30px;
    padding-left: 30px; }
  .NumericInput.small {
    display: inline-block;
    width: 49%; }

.BooleanInput .bp3-switch {
  margin-bottom: 0; }
  .BooleanInput .bp3-switch:last-child {
    margin-top: 0; }

.BooleanInput.small {
  width: 49%;
  display: inline-flex; }

.BooleanInput.bp3-form-group.bp3-inline label.bp3-label {
  line-height: inherit; }


.RadioInput .bp3-label {
  margin-bottom: 5px; }

.Input .bp3-label {
  font-weight: 700; }

.Input.small {
  width: 45%;
  display: inline-flex;
  margin-right: 4%; }

.Input, .bp3-form-group.Input {
  margin-bottom: 20px; }
  .Input:last-child, .bp3-form-group.Input:last-child {
    margin-bottom: 0; }


.ListInput .bp3-input-group {
  margin-bottom: 0.25rem; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ExerciseParameters.bp3-card {
  margin-bottom: 1rem;
  padding-bottom: 10px;
  background-color: #d8e1e8; }

.QuestionParameters {
  margin-bottom: 20px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ExerciseQuestion.bp3-card {
  padding: 0; }
  .ExerciseQuestion.bp3-card > * {
    padding: 0 20px; }
    .ExerciseQuestion.bp3-card > *:first-child {
      padding-top: 20px; }
    .ExerciseQuestion.bp3-card > *:last-child {
      padding-bottom: 20px; }
  .ExerciseQuestion.bp3-card .question-header {
    background-color: #0e5a8a;
    padding-bottom: 20px; }
    .ExerciseQuestion.bp3-card .question-header.has-no-content {
      padding-top: 5px;
      padding-bottom: 5px; }
      .ExerciseQuestion.bp3-card .question-header.has-no-content .card-header {
        display: flex;
        width: 100%;
        justify-content: space-between; }
    .ExerciseQuestion.bp3-card .question-header .card-header {
      color: #ffffff;
      position: relative;
      margin: 0; }
      .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content .bp3-editable-text-content, .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content .bp3-editable-text-input {
        max-width: 100%; }
      .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content.bp3-editable-text-editing {
        color: #10161a; }
      .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content.bp3-editable-text-placeholder .bp3-editable-text-content {
        color: #bfccd6; }
      .ExerciseQuestion.bp3-card .question-header .card-header .delete-question-button {
        position: absolute;
        right: 0; }
  .ExerciseQuestion.bp3-card .question-content {
    padding-top: 20px; }
    .ExerciseQuestion.bp3-card .question-content button.add-answer {
      width: 100%;
      padding: 20px 10px; }
  .ExerciseQuestion.bp3-card .answer {
    margin-bottom: 10px; }

.AnswerParameters .Input:last-child {
  margin-bottom: 0; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ExerciseAnswer.bp3-card {
  padding: 0; }
  .ExerciseAnswer.bp3-card > * {
    padding: 0 10px; }
    .ExerciseAnswer.bp3-card > *:first-child {
      padding-top: 10px; }
    .ExerciseAnswer.bp3-card > *:last-child {
      padding-bottom: 10px; }
  .ExerciseAnswer.bp3-card .answer-header {
    background-color: #ced9e0;
    padding-bottom: 10px; }
  .ExerciseAnswer.bp3-card .AnswerParameters {
    padding-top: 10px; }

.InstanceProjects .project-card {
  margin-bottom: 8px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ProjectCard.bp3-card {
  background-color: #f5f8fa; }
  .ProjectCard.bp3-card.selected {
    background-color: #ced9e0; }
  .ProjectCard.bp3-card .drag-handle {
    padding: 8px 8px 7px 0;
    margin-left: -6px; }
  .ProjectCard.bp3-card .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px; }
    .ProjectCard.bp3-card .title .name {
      margin: 0 16px 0 0;
      flex-grow: 1; }
      .ProjectCard.bp3-card .title .name .slug {
        font-size: 0.8em;
        color: #738694; }
  .ProjectCard.bp3-card .description {
    margin-bottom: 0; }

.bp3-portal .bp3-overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  pointer-events: none; }
  .bp3-portal .bp3-overlay-content > * {
    pointer-events: all; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Admin .bp3-navbar-heading {
  height: 100%; }
  .Admin .bp3-navbar-heading a {
    float: left;
    font-size: 1.25rem;
    height: 100%;
    color: white; }
    .Admin .bp3-navbar-heading a.title {
      line-height: 50px; }
      .Admin .bp3-navbar-heading a.title:hover {
        text-decoration: none;
        color: #ced9e0; }
    .Admin .bp3-navbar-heading a img, .Admin .bp3-navbar-heading a span {
      height: 100%; }
    .Admin .bp3-navbar-heading a img {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      margin-right: 1rem; }

.Report .Content section {
  margin-bottom: 32px; }

.Report .Content .bp3-html-table {
  margin: auto; }
  .Report .Content .bp3-html-table td {
    text-align: center; }
  .Report .Content .bp3-html-table td:first-child {
    text-align: right; }

.Report .Content .bp3-card > *:last-child {
  margin-bottom: 0; }

.Report .Content .question .answer.correct {
  font-weight: 700; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay; }
  .Login .bp3-overlay.bp3-overlay-inline {
    display: flex;
    justify-content: center; }
    .Login .bp3-overlay.bp3-overlay-inline .login-overlay {
      padding: 2rem;
      margin-top: 5rem;
      font-size: 2rem;
      font-weight: 700; }
  .Login .container {
    padding-top: 3rem; }
    .Login .container .form-container {
      background-color: rgba(216, 225, 232, 0.8); }
      .Login .container .form-container form {
        margin-top: 2rem; }
        .Login .container .form-container form button {
          margin-top: 1rem; }

.Overlay .bp3-card {
  padding: 3rem; }

.ProcessingOverlay {
  z-index: 100; }
  .ProcessingOverlay .Overlay {
    z-index: 100; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ErrorOverlay .bp3-card {
  display: flex;
  align-items: center;
  background-color: #a82a2a; }
  .ErrorOverlay .bp3-card .bp3-non-ideal-state {
    color: #ffffff; }
    .ErrorOverlay .bp3-card .bp3-non-ideal-state .bp3-non-ideal-state-visual .bp3-icon {
      color: #ff7373; }
    .ErrorOverlay .bp3-card .bp3-non-ideal-state .bp3-heading {
      color: #ffffff; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
body {
  font-family: Lato, sans-serif;
  padding-top: 50px; }

#root a {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }

#root .bp3-dark a, #root .bp3-dark a:hover {
  color: inherit; }

#root .bp3-dark .bp3-button.bp3-minimal:disabled,
#root .bp3-dark .bp3-button.bp3-minimal:disabled:hover,
#root .bp3-dark .bp3-button.bp3-minimal.bp3-disabled,
#root .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover {
  color: rgba(167, 182, 194, 0.6); }

#root .bp3-html-table th, #root .bp3-html-table td {
  vertical-align: middle; }

#root .bp3-html-table th {
  text-align: center; }
  #root .bp3-html-table th.name {
    width: 100%; }

#root .bp3-html-table tr.dragged {
  width: 100%; }
  #root .bp3-html-table tr.dragged th, #root .bp3-html-table tr.dragged td {
    border: none; }
  #root .bp3-html-table tr.dragged td.actions {
    display: none; }

#root .drag-handle {
  cursor: -webkit-grab;
  cursor: grab; }

#root .dragged .drag-handle {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.disabled {
  opacity: 0.5;
  pointer-events: none; }

.table th, .table td {
  vertical-align: middle; }

.table th {
  text-align: center; }
  .table th.name {
    width: 100%; }

.table td.actions {
  text-align: right;
  white-space: nowrap; }
  .table td.actions * {
    margin-right: 0.5rem; }
    .table td.actions *:last-child {
      margin-right: 0; }

.clickable {
  cursor: pointer; }

input.invisible-edit, textarea.invisible-edit {
  border-color: #00000000;
  background-color: transparent;
  background-color: initial; }
  input.invisible-edit:hover, input.invisible-edit:focus, textarea.invisible-edit:hover, textarea.invisible-edit:focus {
    border: 1px solid #ced4da; }
  input.invisible-edit:focus, textarea.invisible-edit:focus {
    background-color: #fff; }

.bp3-button-group.modal-buttons {
  display: flex;
  width: 100%; }
  .bp3-button-group.modal-buttons .bp3-button {
    flex: 1 1; }

.bp3-input {
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif; }

.bp3-popover-content {
  padding: 10px; }
  .bp3-popover-content > *:last-child {
    margin-bottom: 0; }

