/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.AddExerciseModal .bp3-heading {
  margin-bottom: 2rem; }

.AddExerciseModal .exercise-select .bp3-popover-target {
  margin-bottom: 1rem; }

.AddExerciseModal .exercise-select .bp3-popover-target, .AddExerciseModal .exercise-select .bp3-button {
  width: 100%; }

.bp3-popover-content .select-exercise-choice {
  cursor: pointer;
  background-color: #e1e8ed;
  padding: 8px;
  margin-bottom: 2px; }
  .bp3-popover-content .select-exercise-choice h4, .bp3-popover-content .select-exercise-choice p {
    margin: 0; }
  .bp3-popover-content .select-exercise-choice:hover {
    background-color: #ced9e0; }
