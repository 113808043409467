/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay; }
  .Login .bp3-overlay.bp3-overlay-inline {
    display: flex;
    justify-content: center; }
    .Login .bp3-overlay.bp3-overlay-inline .login-overlay {
      padding: 2rem;
      margin-top: 5rem;
      font-size: 2rem;
      font-weight: 700; }
  .Login .container {
    padding-top: 3rem; }
    .Login .container .form-container {
      background-color: rgba(216, 225, 232, 0.8); }
      .Login .container .form-container form {
        margin-top: 2rem; }
        .Login .container .form-container form button {
          margin-top: 1rem; }
