/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ContentWithSidebar .ContentToolbar {
  position: fixed;
  top: 0;
  right: 0;
  padding: 60px 315px 10px 15px;
  z-index: 1;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  width: 100%;
  height: 95px; }
  .ContentWithSidebar .ContentToolbar .bp3-navbar-group {
    height: 25px; }
  .ContentWithSidebar .ContentToolbar.bp3-dark {
    background-color: #5c7080; }
  .ContentWithSidebar .ContentToolbar + .Content {
    margin-top: 45px; }

.ContentWithSidebar .Content {
  padding-top: 20px;
  margin-right: 300px;
  display: flex;
  justify-content: center; }
  .ContentWithSidebar .Content > div {
    width: 100%;
    max-width: 600px; }

.ContentWithSidebar .Sidebar {
  position: fixed;
  right: 0;
  top: 0;
  padding: 65px 15px 15px;
  z-index: 2;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  background-color: #5c7080;
  height: 100%;
  width: 300px;
  overflow-y: auto; }
  .ContentWithSidebar .Sidebar > * {
    margin-bottom: 10px; }
