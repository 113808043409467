/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ProjectCard.bp3-card {
  background-color: #f5f8fa; }
  .ProjectCard.bp3-card.selected {
    background-color: #ced9e0; }
  .ProjectCard.bp3-card .drag-handle {
    padding: 8px 8px 7px 0;
    margin-left: -6px; }
  .ProjectCard.bp3-card .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px; }
    .ProjectCard.bp3-card .title .name {
      margin: 0 16px 0 0;
      flex-grow: 1; }
      .ProjectCard.bp3-card .title .name .slug {
        font-size: 0.8em;
        color: #738694; }
  .ProjectCard.bp3-card .description {
    margin-bottom: 0; }
