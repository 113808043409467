/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ErrorOverlay .bp3-card {
  display: flex;
  align-items: center;
  background-color: #a82a2a; }
  .ErrorOverlay .bp3-card .bp3-non-ideal-state {
    color: #ffffff; }
    .ErrorOverlay .bp3-card .bp3-non-ideal-state .bp3-non-ideal-state-visual .bp3-icon {
      color: #ff7373; }
    .ErrorOverlay .bp3-card .bp3-non-ideal-state .bp3-heading {
      color: #ffffff; }
