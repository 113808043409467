/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ExerciseQuestion.bp3-card {
  padding: 0; }
  .ExerciseQuestion.bp3-card > * {
    padding: 0 20px; }
    .ExerciseQuestion.bp3-card > *:first-child {
      padding-top: 20px; }
    .ExerciseQuestion.bp3-card > *:last-child {
      padding-bottom: 20px; }
  .ExerciseQuestion.bp3-card .question-header {
    background-color: #0e5a8a;
    padding-bottom: 20px; }
    .ExerciseQuestion.bp3-card .question-header.has-no-content {
      padding-top: 5px;
      padding-bottom: 5px; }
      .ExerciseQuestion.bp3-card .question-header.has-no-content .card-header {
        display: flex;
        width: 100%;
        justify-content: space-between; }
    .ExerciseQuestion.bp3-card .question-header .card-header {
      color: #ffffff;
      position: relative;
      margin: 0; }
      .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content .bp3-editable-text-content, .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content .bp3-editable-text-input {
        max-width: 100%; }
      .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content.bp3-editable-text-editing {
        color: #10161a; }
      .ExerciseQuestion.bp3-card .question-header .card-header .card-header-content.bp3-editable-text-placeholder .bp3-editable-text-content {
        color: #bfccd6; }
      .ExerciseQuestion.bp3-card .question-header .card-header .delete-question-button {
        position: absolute;
        right: 0; }
  .ExerciseQuestion.bp3-card .question-content {
    padding-top: 20px; }
    .ExerciseQuestion.bp3-card .question-content button.add-answer {
      width: 100%;
      padding: 20px 10px; }
  .ExerciseQuestion.bp3-card .answer {
    margin-bottom: 10px; }
