/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ExerciseAnswer.bp3-card {
  padding: 0; }
  .ExerciseAnswer.bp3-card > * {
    padding: 0 10px; }
    .ExerciseAnswer.bp3-card > *:first-child {
      padding-top: 10px; }
    .ExerciseAnswer.bp3-card > *:last-child {
      padding-bottom: 10px; }
  .ExerciseAnswer.bp3-card .answer-header {
    background-color: #ced9e0;
    padding-bottom: 10px; }
  .ExerciseAnswer.bp3-card .AnswerParameters {
    padding-top: 10px; }
