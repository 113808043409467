/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Admin .bp3-navbar-heading {
  height: 100%; }
  .Admin .bp3-navbar-heading a {
    float: left;
    font-size: 1.25rem;
    height: 100%;
    color: white; }
    .Admin .bp3-navbar-heading a.title {
      line-height: 50px; }
      .Admin .bp3-navbar-heading a.title:hover {
        text-decoration: none;
        color: #ced9e0; }
    .Admin .bp3-navbar-heading a img, .Admin .bp3-navbar-heading a span {
      height: 100%; }
    .Admin .bp3-navbar-heading a img {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      margin-right: 1rem; }
